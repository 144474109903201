import { ContentfulLinkIcon } from '@/components/contentful-link-icon';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardDeepBlue = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, icon, linkIconTitle, linkUrl } = card;

  const content = (
    <div className="bg-brand-deep-blue p-6 flex flex-col h-full group">
      <h4 className="text-brand-green m-0">{title}</h4>
      <div className="mt-6 flex-grow text-white w-70">
        <RichText data={shortDescription} />
      </div>
      {icon && linkIconTitle && (
        <ContentfulLinkIcon
          icon={icon}
          linkIconTitle={linkIconTitle}
          textColor="white"
        />
      )}
    </div>
  );
  return (
    <div>
      {linkUrl ? (
        <Link
          href={`${linkUrl}`}
          className="CardDeepBlue block h-[400px] min-[1700px]:h-[400px]"
        >
          {content}
        </Link>
      ) : (
        <div className="CardDeepBlue block h-[400px] min-[1700px]:h-[400px]">
          {content}
        </div>
      )}
    </div>
  );
};

export default CardDeepBlue;
