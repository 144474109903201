const YouTubeVideo = ({ data, videoHeight }: any) => {
  return (
    data && (
      <div className="">
        <iframe
          width="100%"
          className={`${videoHeight} aspect-video`}
          src={data}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    )
  );
};

export default YouTubeVideo;
