import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';

const CardSimpleText = ({ card }: CardTemplateIC) => {
  const { title, shortDescription } = card;

  return (
    <div className="CardSimpleText block h-full">
      <div className="flex flex-col h-full justify-between group">
        <div>
          <h2 className="m-0">{title}</h2>
          <div className="mt-6">
            <RichText data={shortDescription} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSimpleText;
