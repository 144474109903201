import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';

const CardTemplate4 = ({ card }: CardTemplateIC) => {
  const { title, shortDescription } = card;
  return (
    <div className="CardTemplate4 block h-[330px] min-[1700px]:h-[400px]">
      <div className="border-6 solid border-brand-green p-6 min-[1700px]:p-12 flex flex-col justify-between h-full">
        <h3 className="m-0 break-words">{title}</h3>
        <div className='flex text-start h-12'>
          <RichText data={shortDescription} />
        </div>
      </div>
    </div>
  );
};

export default CardTemplate4;
