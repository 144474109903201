import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';

const CardSimpleLeft = ({ card }: CardTemplateIC) => {
  const { title, shortDescription } = card;
  return (
    <div className='CardSimpleLeft ml-0 md:ml-10 lg:ml-20 md:mr-4 xl:ml-64 md:max-w-[420px]'>
      <h2>{title}</h2>
      <div className="mt-7">
        <RichText data={shortDescription} />
      </div>
      
    </div>
  );
};

export default CardSimpleLeft;
