import RichText from '@/ctf-components/rich-text';

interface PropsIC {
  title: string;
  coloredTitle: boolean;
  // eslint-disable-next-line react/require-default-props
  richText?: any;
}

const HeroBanner = (props: PropsIC) => {
  const { title, coloredTitle, richText } = props;

  const getColoredTitle = (title: string) => {
    if (!coloredTitle) return null;

    const sentences = title.split('.');
    if (sentences.length > 2) {
      return sentences[title?.split('.').length - 2] + '.';
    }
    return coloredTitle ? title?.split(' ').pop() : null;
  };

  const greenTitle = getColoredTitle(title);
  const blackTitle =
    coloredTitle && greenTitle ? title.replace(greenTitle, ' ') : title;

  return (
    <div className="HeroBanner mt-20">
      <div className=" mb-6">
        <h1 className="break-words">
          {blackTitle}{' '}
          {coloredTitle && greenTitle && (
            <span className="text-brand-green">{greenTitle}</span>
          )}
        </h1>
      </div>
      {richText && (
        <div className="text-lg font-medium mb-2">
          <RichText data={richText} />
        </div>
      )}
    </div>
  );
};

export default HeroBanner;
