import { ArrowSvg } from '@/components/arrow';
import RichText from '@/ctf-components/rich-text';
import { SectionCardIC } from '@/lib/interface-types';
import Link from 'next/link';

interface CardTabProps {
  card: SectionCardIC;
  darkMode: boolean;
}
const CardTab = ({ card, darkMode }: CardTabProps) => {
  const { shortDescription, linkIconTitle, linkUrl } = card;

  const showLinkButton = linkUrl && linkIconTitle;

  return (
    <div className="flex flex-col gap-y-8">
      <div>
        <RichText data={shortDescription} />
      </div>
      {showLinkButton && (
        <div>
          <Link
            href={`${linkUrl}`}
            target="_blank"
            className="flex group items-center"
          >
            <span className="relative overflow-hidden mr-2 text-subtitle font-bold uppercase">
              {linkIconTitle}
              <span
                className={`absolute inset-x-0 bottom-0 h-0.5 ${
                  darkMode ? 'bg-white' : 'bg-black'
                } transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0`}
              ></span>
            </span>
            <ArrowSvg color={darkMode ? '#fff' : '#000'} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default CardTab;
