import { ArrowSvg } from '@/components/arrow';
import ContentfulImage from '@/components/contentful-image';
import { useOSContext } from '@/context/OSContext';
import AddPlusIcon from '@/public/icons/add-plus-icon.svg';
import RemoveMinusIcon from '@/public/icons/remove-minus-icon.svg';
import Link from 'next/link';
import { useState } from 'react';
import RichText from '../rich-text';

const Accordion = ({ item }: any) => {
  const { title, description, shortDescription, linkUrl, linkIconTitle } = item;
  const [isDescriptionVisible, setDescriptionVisible] = useState(false);
  const osContext = useOSContext();

  const toggleDescriptionVisibility = () => {
    setDescriptionVisible((prevState) => !prevState);
  };

  const showLinkButton = linkUrl && linkIconTitle;

  return (
    <div>
      <div
        className={`flex gap-5 justify-between cursor-pointer ${
          isDescriptionVisible ? '0' : 'pb-8'
        }`}
        onClick={toggleDescriptionVisibility}
      >
        <h5 className="m-0 pr-5">{title}</h5>
        <div
          className={`min-w-[24px] ${
            osContext?.os === 'Windows' && 'mt-[6px]'
          }`}
        >
          <ContentfulImage
            src={isDescriptionVisible ? RemoveMinusIcon : AddPlusIcon}
            width={24}
            height={24}
            className=""
          />
        </div>
      </div>
      {isDescriptionVisible && (
        <div className="py-4">
          <RichText data={description || shortDescription} />
          {showLinkButton && (
            <div className="my-8">
              <Link
                href={`${linkUrl}`}
                target="_blank"
                className="flex group items-center"
              >
                <span className="relative overflow-hidden mr-2 text-subtitle font-bold uppercase">
                  {linkIconTitle}
                  <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0"></span>
                </span>
                <ArrowSvg color="#000" />
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Accordion;
