import ContentfulImage from '@/components/contentful-image';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardTemplate7 = ({ card }: CardTemplateIC) => {
  const { title, icon, linkIconTitle, linkUrl, linkMailto, cardImage } = card;

  const content = (
    <>
      {cardImage?.url && (
        <div className="relative h-[330px] min-[1700px]:h-[400px]">
          <ContentfulImage
            src={cardImage?.url}
            alt={cardImage?.title}
            className="object-cover"
            fill
            sizes="99.99vw"
          />
        </div>
      )}

      <div className="flex mt-1">
        <div
          className={`flex items-center mt-3  ${
            linkUrl?.includes('goo.gl') ||
            linkMailto?.includes('@consulteer.com')
              ? 'mt-6 '
              : 'mt-0'
          }`}
        >
          {icon?.url && linkUrl && (
            <ContentfulImage
              src={icon?.url}
              alt="icon"
              className="object-cover "
              sizes="99.99vw"
              width={icon?.width}
              height={icon?.height}
            />
          )}
          {linkIconTitle && <p className={`more-info ${icon?.url && linkUrl ? 'ml-2.5' : ''}`}>{linkIconTitle}</p>}
        </div>
        {title && (
          <div className="mt-2.5">
            <p className="uppercase font-bold">{title}</p>
          </div>
        )}
      </div>
    </>
  );

  const wrappedContent = linkUrl ? (
    <Link
      className="CardTemplate7 flex flex-col"
      href={`${linkUrl}`}
      target={`${
        linkUrl?.includes('goo.le') || linkUrl?.includes('mailto:')
          ? '_blank'
          : '_self'
      }`}
    >
      {content}
    </Link>
  ) : (
    <div className="CardTemplate7 flex flex-col">{content}</div>
  );

  return wrappedContent;
};

export default CardTemplate7;
