import { ArrowSvg } from '@/components/arrow';
import ContentfulImage from '@/components/contentful-image';
import { BannerPropsIC } from '@/lib/interface-types';
import LinkedinIcon from '@/public/icons/linkedin-blue-bg.svg';
import { useOSContext } from '@/context/OSContext';
import Link from 'next/link';

const BannerSingle = ({ data }: BannerPropsIC) => {
  const {
    linkedIn,
    personMail,
    personName,
    personPosition,
    pictureOfPerson,
    quote
  } = data;
  const OsContext = useOSContext();

  return (
    <div className="BannerSingle flex gap-12 xl:gap-[3.75rem] mt-20">
      <div className="relative hidden md:block h-[330px] overflow-hidden min-w-[330px] max-w-[330px]">
        <ContentfulImage
          src={pictureOfPerson.url}
          alt={pictureOfPerson.title}
          className="object-cover"
          fill
          sizes="99.99vw"
        />
      </div>
      <div className={`${linkedIn?.urlPath ? '' : 'place-content-center'} `}>
        <div className="flex flex-col">
          <h3 className="m-0">{quote}</h3>
          <div className="relative h-[330px] block mt-10 md:hidden w-[330px]">
            <ContentfulImage
              src={pictureOfPerson.url}
              alt={pictureOfPerson.title}
              className="object-cover"
              fill
              sizes="99.99vw"
            />
          </div>
          {(personName || personPosition || personMail) && (
            <div className={`flex flex-col sm:flex-row ${quote ? 'mt-6 lg:mt-10' : ''}`}>
              {linkedIn?.urlPath && (
                <div className="mr-3 cursor-pointer">
                  <Link
                    href={`${linkedIn?.urlPath}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ContentfulImage
                      src={LinkedinIcon}
                      alt="LinkedinIcon"
                      className="object-cover"
                      sizes="99.99vw"
                      width={25}
                      height={25}
                    />
                  </Link>
                </div>
              )}
              <div>
                <p className="mt-3 sm:mt-0 uppercase font-bold">{personName}</p>
                <p>{personPosition}</p>
                <Link href={`mailto:${personMail}`} target="_blank" className="flex group">
                  <p className="relative overflow-hidden mr-2">
                    {personMail}
                    <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0"></span>
                  </p>
                  <div className={`${OsContext?.os !== 'MacOS' && 'mt-[6px]'} ${OsContext?.os === 'MacOS' && 'mt-[5px]'}`}>
                    <ArrowSvg
                      color='#000'
                    />
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerSingle;
