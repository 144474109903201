import ContentfulImage from '@/components/contentful-image';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardTemplate3 = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, icon, linkIconTitle, linkUrl, linkMailto } =
    card;
  return (
    <Link
      href={`${linkMailto ? `mailto:${linkMailto}` : linkUrl}`}
      target={`${
        linkUrl?.includes('goo.gl') || linkMailto?.includes('@consulteer.com')
          ? '_blank'
          : '_self'
      }`}
      className={`CardTemplate3 block
      ${
        linkUrl?.includes('goo.gl') ||
        linkMailto?.includes('@consulteer.com') ||
        !linkUrl
          ? 'h-full'
          : 'h-[330px] min-[1700px]:h-[400px]'
      }
      `}
    >
      <div className="flex flex-col h-full justify-between">
        <div>
          <h4 className='m-0'>{title}</h4>
          <div
            className={`mt-6  ${
              linkUrl?.includes('goo.gl') ||
              linkMailto?.includes('@consulteer.com')
                ? ''
                : 'flex-grow'
            }`}
          >
            <RichText data={shortDescription} />
          </div>
        </div>
        {(linkMailto || linkUrl) && icon && (
          <div
            className={`flex items-center  ${
              linkUrl?.includes('goo.gl') ||
              linkMailto?.includes('@consulteer.com')
                ? 'mt-6 '
                : 'mt-0'
            }`}
          >
            <ContentfulImage
              src={icon?.url}
              alt="icon"
              className="object-cover "
              sizes="99.99vw"
              width={icon?.width}
              height={icon?.height}
            />
            <p className="more-info ml-2.5 mt-0.5">{linkIconTitle}</p>
          </div>
        )}
      </div>
    </Link>
  );
};

export default CardTemplate3;
