import Accordion from '../accordion/accordion';
import Card from '../card/card';

const AccordionSection = ({ title, items, layout, item }: any) => {
  return (
    <div
      className={`Accordion mt-12 sm:mt-12 lg:mt-20 ${
        layout === 'Accordion right - Article' ||
        layout === 'Accordion left - Article'
          ? 'grid md:grid-cols-2'
          : layout === 'Accordion full-width' || layout === 'full-width'
          ? 'w-full'
          : 'grid lg:grid-cols-3 '
      } gap-12 xl:gap-[3.75rem]`}
    >
      {(layout === 'left' ||
        layout === 'Accordion left - Article' ||
        layout === 'Accordion left - Category Page') && (
        <div
          className={`${
            layout === 'Accordion left - Article'
              ? 'col-span-2 lg:col-span-1'
              : 'col-span-2'
          }`}
        >
          <Card
            card={item}
            sectionLayout={layout}
            classIndex={0}
            slug={''}
            sectionPadding={false}
          />
        </div>
      )}
      <div
        className={
          layout === 'full-width' ? 'w-full' : 'col-span-2 lg:col-span-1'
        }
      >
        {title && <h2 className="my-0">{title}</h2>}
        <div className={`${title ? 'mt-10' : 'mt-0'}`}>
          {items.map(
            (item: any, index: number) =>
              (item.description || item.shortDescription) && (
                <Accordion key={index} item={item} />
              )
          )}
        </div>
      </div>
      {(layout === 'right' ||
        layout === 'Accordion right - Article' ||
        layout === 'Accordion right - Category Page') && (
        <div
          className={`${
            layout === 'Accordion right - Article'
              ? 'col-span-2 lg:col-span-1'
              : 'col-span-2'
          }`}
        >
          <Card
            card={item}
            sectionLayout={layout}
            classIndex={1}
            slug={''}
            sectionPadding={false}
          />
        </div>
      )}
    </div>
  );
};

export default AccordionSection;
