import ContentfulImage from '@/components/contentful-image';
import { ContentfulLinkIcon } from '@/components/contentful-link-icon';
import { useOSContext } from '@/context/OSContext';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardDescriptionImage = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, linkIconTitle, linkUrl, cardImage, icon } =
    card;
  return (
    <Link
      href={`${linkUrl}`}
      className="CardDescriptionImage block h-full group"
      target={`${
        linkUrl?.includes('goo.le') || linkUrl?.includes('mailto:')
          ? '_blank'
          : '_self'
      }`}
    >
      <div className="flex flex-col w-full h-full">
        <div className="relative w-full h-[330px] min-[1700px]:h-[400px] overflow-hidden">
          {cardImage && (
            <ContentfulImage
              src={cardImage?.url}
              alt="icon"
              className="group-hover:scale-125 duration-500  object-cover"
              sizes="99.99vw"
              fill
            />
          )}
        </div>
        <div className="flex flex-col flex-grow ml-2.5 mt-4">
          <h5 className="m-0">{title}</h5>
          <div className="mt-6 flex-grow gray-text-children">
            <RichText data={shortDescription} />
          </div>
          <div className="mt-6">
            {icon && linkIconTitle && (
              <ContentfulLinkIcon icon={icon} linkIconTitle={linkIconTitle} />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardDescriptionImage;
