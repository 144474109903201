import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';

const CardSimpleRight = ({ card }: CardTemplateIC) => {
  const { title, shortDescription } = card;
  return (
    <div className='CardSimpleRight mr-0 md:mr-10 md:ml-5 lg:ml-5 md:max-w-[420px]'>
      <h2 className='max-w-[400px]'>{title}</h2>
      <div className='mt-7'>
        <RichText data={shortDescription} />
      </div>
      
    </div>
  );
};

export default CardSimpleRight;
