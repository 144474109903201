import ContentfulImage from '@/components/contentful-image';
import { ContentfulLinkIcon } from '@/components/contentful-link-icon';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardImage = ({ card }: CardTemplateIC) => {
  const { title, linkIconTitle, linkUrl, cardImage, icon } = card;
  return (
    <Link
      href={`${linkUrl}`}
      className="CardImage block group border-gray"
      target={`${
        linkUrl?.includes('goo.le') || linkUrl?.includes('mailto:')
          ? '_blank'
          : '_self'
      }`}
    >
      <div className="flex flex-col w-full h-full pb-6">
        <div className="relative w-full h-[330px] min-[1700px]:h-[400px]">
          {cardImage && (
            <ContentfulImage
              src={cardImage?.url}
              alt="icon"
              className="object-cover"
              sizes="99.99vw"
              fill
            />
          )}
        </div>
        <div className="ml-4 mt-4">
          <h4 className="m-0">{title}</h4>
          {icon && linkIconTitle && (
            <ContentfulLinkIcon
              icon={icon}
              linkIconTitle={linkIconTitle}
              margin="8"
            />
          )}
        </div>
      </div>
    </Link>
  );
};

export default CardImage;
