import { ArrowSvg } from '@/components/arrow';
import { useOSContext } from '@/context/OSContext';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardText = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, icon, linkUrl, linkMailto } = card;
  const OsContext = useOSContext();

  return (
    <Link
      href={`${linkMailto ? `mailto:${linkMailto}` : linkUrl}`}
      target={`${
        linkUrl?.includes('goo.gl') || linkMailto?.includes('@consulteer.com')
          ? '_blank'
          : '_self'
      }`}
      className={`CardText block
      ${
        linkUrl?.includes('goo.gl') ||
        linkMailto?.includes('@consulteer.com') ||
        !linkUrl
          ? 'h-full'
          : 'h-[330px] min-[1700px]:h-[400px]'
      }
      `}
    >
      <div className="flex flex-col h-full justify-between group">
        <div>
          <h4 className="m-0">{title}</h4>
          <div
            className={`mt-6  ${
              linkUrl?.includes('goo.gl') ||
              linkMailto?.includes('@consulteer.com')
                ? ''
                : 'flex-grow'
            }`}
          >
            <RichText data={shortDescription} />
          </div>
        </div>
        {icon && linkMailto && (
          <div className="flex items-center ">
            <p className="mt-1 mr-2 lowercase relative overflow-hidden">
              {linkMailto}
              <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0"></span>
            </p>
            <div className={`${OsContext?.os !== 'MacOS' && 'mt-[6px]'}`}>
              <ArrowSvg color="#000" />
            </div>
            
          </div>
        )}
      </div>
    </Link>
  );
};

export default CardText;
