import { CardSectionItem } from '@/lib/interface-types';
import { useState } from 'react';
import CardTab from '../card/templates/card-tab';

interface TabsProps {
  data: CardSectionItem[];
  title: string;
  darkMode: boolean;
}

const Tabs = ({ data, title, darkMode }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const setActiveClass = (tab: number) =>
    activeTab === tab ? 'text-brand-green' : '';

  return (
    <div
      className={`flex flex-col py-8 px-12 mt-20 gap-y-8 ${
        darkMode ? 'bg-brand-deep-blue text-white fill-white' : ''
      }`}
    >
      <h2 className={`!my-0 ${darkMode ? 'text-brand-green' : ''}`}>{title}</h2>
      <div className="flex gap-x-[18px]">
        {data.map((item, index) => (
          <li
            className={`uppercase text-base list-none cursor-pointer mt-0 ${setActiveClass(
              index
            )}`}
            key={index}
            onClick={() => setActiveTab(index)}
          >
            {item.title}
          </li>
        ))}
      </div>
      <div className="xl:w-3/4">
        <CardTab card={data[activeTab]} darkMode={darkMode} />
      </div>
    </div>
  );
};

export default Tabs;
