import { ContentfulImageIC } from '@/lib/interface-types';
import ContentfulImage from './contentful-image';
import { useOSContext } from '@/context/OSContext';
interface ContentfulLinkIconProps {
  linkIconTitle: string;
  icon: ContentfulImageIC;
  textColor?: 'white' | 'black' | 'gray-500';
  lowercase?: boolean
  margin?: string;
}

export const ContentfulLinkIcon = ({
  linkIconTitle,
  icon,
  textColor = 'black',
  lowercase = false,
  margin
}: ContentfulLinkIconProps) => {
  const OSContext = useOSContext();

  return (
    <div className={`flex items-center mt-${margin}`}>
      <p
      className={`relative overflow-hidden more-info mt-1 mr-1 text-${textColor} ${lowercase ? 'lowercase' : ''}`}>
        {linkIconTitle}
        <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0"></span>
      </p>
      <ContentfulImage
        src={icon?.url}
        alt="icon"
        className={`object-cover ${OSContext?.os === 'Windows' && 'mt-[4px]'}`}
        sizes="99.99vw"
        width={icon?.width}
        height={icon?.height}
      />
    </div>
  );
};
