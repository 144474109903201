import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';

const CardTemplate6 = ({ card }: CardTemplateIC) => {
  const { title, shortDescription } = card;
  return (
    <div className="CardTemplate6 block h-full">
      {title && <h3 className='m-0'>{title}</h3>}
      <div className='mt-6'>
        <RichText data={shortDescription} />
      </div>
    </div>
  );
};

export default CardTemplate6;
