import ContentfulImage from '@/components/contentful-image';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardTemplate1 = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, icon, linkIconTitle, linkUrl } = card;
  return (
    <Link
      href={`${linkUrl}`}
      className="CardTemplate1 block border-6 solid border-brand-green p-6 h-[330px] min-[1700px]:h-[400px]"
      target={`${
        linkUrl?.includes('goo.le') || linkUrl?.includes('mailto:')
          ? '_blank'
          : '_self'
      }`}
    >
      <div className="flex flex-col h-full">
        <h4 className='m-0'>{title}</h4>
        <div className="mt-6 flex-grow">
          <RichText data={shortDescription} />
        </div>
        {icon && linkIconTitle && (
          <div className="flex items-center">
            <ContentfulImage
              src={icon?.url}
              alt="icon"
              className="object-cover "
              sizes="99.99vw"
              width={icon?.width}
              height={icon?.height}
            />
            <p className="more-info ml-2.5 mt-0.5">{linkIconTitle}</p>
          </div>
        )}
      </div>
    </Link>
  );
};

export default CardTemplate1;
