import { ArrowSvg } from '@/components/arrow';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardSimpleIconLink = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, linkUrl } = card;

  return (
    <Link href={linkUrl || ''} target={'_self'} className="CardText block mt-3 group">
      <div className="flex flex-col h-full justify-between group">
        <div>
          <div className="flex items-center justify-between">
            <h4 className="m-0 relative overflow-hidden max-w-[90%]">
              {title}
              <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0"></span>
              </h4>
            <ArrowSvg color="#000"/>
          </div>
          <div className="mt-6">
            <RichText data={shortDescription} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardSimpleIconLink;
