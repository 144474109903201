import ContentfulImage from '@/components/contentful-image';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardTemplate2 = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, icon, linkIconTitle, linkUrl } = card;

  const content = (
    <div className="bg-brand-deep-blue p-6 flex flex-col h-full">
      <h4 className="text-brand-green m-0">{title}</h4>
      <div className="mt-6 flex-grow text-white  ">
        <RichText data={shortDescription} />
      </div>
      {icon && linkIconTitle && (
        <div className="flex items-center">
          <ContentfulImage
            src={icon?.url}
            alt="icon"
            className="object-cover "
            sizes="99.99vw"
            width={icon?.width}
            height={icon?.height}
          />
          <p className="more-info ml-2.5 mt-0.5 text-white">{linkIconTitle}</p>
        </div>
      )}
    </div>
  );
  return (
    <div>
      {linkUrl ? (
        <Link
          href={`${linkUrl}`}
          className="CardTemplate2 block h-[330px] min-[1700px]:h-[400px]"
        >
          {content}
        </Link>
      ) : (
        <div className="CardTemplate2 block h-[330px] min-[1700px]:h-[400px]">
          {content}
        </div>
      )}
    </div>
  );
};

export default CardTemplate2;
