import { ContentfulLinkIcon } from '@/components/contentful-link-icon';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';

const CardGreenBorder = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, icon, linkIconTitle } = card;
  return (
    <div className="CardGreenBorder block border-6 solid border-brand-green p-6 h-[400px] min-[1700px]:h-[400px] relative group">
      <div className="flex flex-col h-full">
        <h3 className="m-0">{title}</h3>
        <div className="mt-6 flex-grow">
          <RichText data={shortDescription} />
        </div>
        {icon && linkIconTitle && (
          <ContentfulLinkIcon icon={icon} linkIconTitle={linkIconTitle} />
        )}
        <div className="absolute inset-0 expanding-shadow hidden group-hover:block"></div>
      </div>
    </div>
  );
};

export default CardGreenBorder;
