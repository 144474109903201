import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';

export interface PropsIC {
  data: {
    json: JSON;
  };
}
export interface JSON {
  nodeType: string;
  data: any;
  content: [];
}

const RichText = ({ data }: PropsIC) => {
  return <>{documentToReactComponents(data?.json as Document)}</>;
};

export default RichText;
