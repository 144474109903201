import { ContentfulLinkIcon } from '@/components/contentful-link-icon';
import RichText from '@/ctf-components/rich-text';
import { CardTemplateIC } from '@/lib/interface-types';
import Link from 'next/link';

const CardWhite = ({ card }: CardTemplateIC) => {
  const { title, shortDescription, icon, linkIconTitle, linkUrl } = card;
  return (
    <Link
      href={`${linkUrl}`}
      className="CardWhite block border-6 solid border-brand-green md:border-transparent p-6 h-[400px] min-[1700px]:h-[400px] hover:border-brand-green group"
      target={`${
        linkUrl?.includes('goo.le') || linkUrl?.includes('mailto:')
          ? '_blank'
          : '_self'
      }`}
    >
      <div className="flex flex-col h-full">
        <h4 className="m-0">{title}</h4>
        <div className="mt-6 flex-grow">
          <RichText data={shortDescription} />
        </div>
        {icon && linkIconTitle && (
          <ContentfulLinkIcon icon={icon} linkIconTitle={linkIconTitle} />
        )}
      </div>
    </Link>
  );
};

export default CardWhite;
