import { ContentfulImageIC } from '@/lib/interface-types';
import Link from 'next/link';
import { useState } from 'react';
import CloseIcon from './../public/icons/close-green.svg';
import InstagramIcon from './../public/icons/instagram-video.svg';
import LinkedinIcon from './../public/icons/linkedin-white-bg.svg';
import PlayButton from './../public/icons/play-button.svg';
import YoutubeIcon from './../public/icons/youtube-video.svg';
import ContentfulImage from './contentful-image';
import YouTubeVideo from './videos/youtube-video';
import InstagramIconGreen from './../public/icons/instagram-green.svg';
import LinkedinIconGreen from './../public/icons/linkedin-green.svg';
import YoutubeIconGreen from './../public/icons/youtube-green.svg';



interface ContentfulVideoIC {
  youtubeVideo: string;
  height: string;
  cardImage: ContentfulImageIC;
}

const ContentfulVideo = ({
  youtubeVideo,
  height,
  cardImage
}: ContentfulVideoIC) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const clickOnImage = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const socialMediaLinks = [
    {
      href: 'https://www.instagram.com/consulteer/',
      icon: InstagramIcon,
      hoverIcon: InstagramIconGreen
    },
    {
      href: 'https://www.linkedin.com/company/consulteer/',
      icon: LinkedinIcon,
      hoverIcon: LinkedinIconGreen
    },
    {
      href: 'https://www.youtube.com/@Consulteer',
      icon: YoutubeIcon,
      hoverIcon: YoutubeIconGreen
    }
  ];

  return (
    <div>
      <div
        onClick={clickOnImage}
        className={`relative cursor-pointer flex items-center align-center justify-center ${height}`}
      >
        <ContentfulImage
          src={cardImage?.url}
          alt={cardImage?.title}
          className="object-cover"
          fill
          sizes="99.99vw"
        />
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="bg-brand-green w-16 h-16 rounded-full flex justify-center items-center pl-2">
            <ContentfulImage
              src={PlayButton}
              alt="Icon"
              width={25}
              height={25}
            />
          </div>
        </div>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 bg-brand-deep-blue z-50 flex justify-center items-center">
          <div className="relative w-full max-w-5xl lg:mx-auto bg-white overflow-hidden">
            <div className="flex justify-between items-start sm:items-center px-3 lg:px-0 bg-brand-deep-blue pb-10">
              <h3 className="m-0 text-white max-w-[350px] sm:max-w-max">
                Human. Technology.{' '}
                <span className="text-brand-green">Together.</span>
              </h3>
              <div onClick={closeModal} className="cursor-pointer mt-[5px] sm:mt-0">
                <ContentfulImage
                  src={CloseIcon}
                  alt="Close menu icon"
                  width={16}
                  height={16}
                />
              </div>
            </div>
            <div className="aspect-w-16 aspect-h-9">
              <YouTubeVideo data={youtubeVideo} />
            </div>
            <div className="flex justify-end items-center px-3 pt-8 lg:px-0  bg-brand-deep-blue">
            {socialMediaLinks.map((link, index) => (
              <div
                key={index}
                className="pr-3"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Link href={link.href} target="_blank">
                  <ContentfulImage
                    src={index === hoveredIndex ? link.hoverIcon : link.icon}
                    alt="Social media icon"
                    width={link.icon === YoutubeIcon ? 30 : 25}
                    height={25}
                  />
                </Link>
              </div>
            ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentfulVideo;
