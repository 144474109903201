import { BannerPropsIC } from '@/lib/interface-types';
import BannerSingle from './banner-single';
import BannerSection from './banner-section';

const PersonBanner = ({ data, sectionbaner }: BannerPropsIC) => {
  const BannerComponent = sectionbaner ? <BannerSection data={data}/> : <BannerSingle data={data} />;

  return (
    <div>{BannerComponent}</div>
  )
};

export default PersonBanner;
