import { ArrowSvg } from '@/components/arrow';
import ContentfulImage from '@/components/contentful-image';
import { useOSContext } from '@/context/OSContext';
import { BannerPropsIC } from '@/lib/interface-types';
import LinkedinIcon from '@/public/icons/linkedin-blue-bg.svg';
import Link from 'next/link';

const BannerSection = ({ data }: BannerPropsIC) => {
  const { linkedIn, personMail, personName, personPosition, pictureOfPerson } =
    data;
  const OsContext = useOSContext();

  return (
    <div className="BannerSection flex flex-col">
      <div className="relative h-[330px] min-[1700px]:h-[400px]">
        <ContentfulImage
          src={pictureOfPerson.url}
          alt={pictureOfPerson.title}
          className="object-cover"
          fill
          sizes="99.99vw"
        />
      </div>
      {linkedIn?.urlPath && (
        <div className="mt-4">
          <Link
            href={`${linkedIn?.urlPath}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ContentfulImage
              src={LinkedinIcon}
              alt="LinkedinIcon"
              className="object-cover"
              sizes="99.99vw"
              width={25}
              height={25}
            />
          </Link>
        </div>
      )}
      <div className={`mt-3 ${!linkedIn?.urlPath ? 'mt-2.5' : ''}`}>
        <p className="sm:mt-0 uppercase font-bold">{personName}</p>
        <p className="font-medium">{personPosition}</p>
        <Link href={`mailto:${personMail}`} className="flex group">
          <p className="mr-2 relative overflow-hidden ">
            {personMail}
            <span className="absolute inset-x-0 bottom-0 h-0.5 bg-black transition-transform duration-300 transform -translate-x-full group-hover:translate-x-0"></span>
          </p>
          <div
            className={`${OsContext?.os !== 'MacOS' && 'mt-[6px]'} ${
              OsContext?.os === 'MacOS' && 'mt-[5px]'
            }`}
          >
            {personMail && <ArrowSvg color="#000" />}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BannerSection;
